import { useClaimLab } from '~/stores/claimLab'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (!import.meta.client) {
    return
  }

  const { $auth0 } = useNuxtApp()
  const { isAuthenticated, getAccessTokenSilently } = $auth0()

  if (!isAuthenticated.value) {
    return abortNavigation()
  }

  const token = await getAccessTokenSilently()

  const config = useRuntimeConfig().public
  const claimLabInfo = await $fetch(
    `/api/claim-lab/connector/${to.params.connectorId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'auth-provider': 'auth0',
        ...(config.WORKSPACE_ID && {
          'x-rialtic-workspace-id': config.WORKSPACE_ID,
        }),
      },
    },
  )

  if (!claimLabInfo.enabled) {
    return abortNavigation()
  }
  const claimLab = useClaimLab()

  claimLab.setWorkspaceId(claimLabInfo.workspaceId)
})
