import type { DropDownItem } from '@rialtic/types'

// types/enums
export enum FilterQueryNames {
  SourceTypeLevelOne = 'source_type_level_1',
  Region = 'region',
}

export enum RegionTypeNames {
  Jurisdiction = 'jurisdiction',
  State = 'state',
}

export enum DateRangePresetKey {
  LastSeven = 'lastSeven',
  LastThirty = 'lastThirty',
  LastNinety = 'lastNinety',
  QuarterToDate = 'quarterToDate',
  YearToDate = 'yearToDate',
  SinceInception = 'sinceInception',
  Custom = 'custom',
}

// general constants
export const ALL_LOB = <const>'all_lob'
export const RESULTS_LIMIT = <const>100

export const datePresets: Record<DateRangePresetKey, string> = {
  [DateRangePresetKey.LastSeven]: 'Last 7 days',
  [DateRangePresetKey.LastThirty]: 'Last 30 days',
  [DateRangePresetKey.LastNinety]: 'Last 90 days',
  [DateRangePresetKey.QuarterToDate]: 'Quarter to date',
  [DateRangePresetKey.YearToDate]: 'Year to date',
  [DateRangePresetKey.SinceInception]: 'Since inception',
  [DateRangePresetKey.Custom]: 'Custom',
}

export const dateRangePattern = <const>'yyyy-MM-dd'
export const dateRangePatternAlt = <const>'MM/dd/yy'

//TODO: change to enum in lib
export const draftOperations = <const>{
  create: 'create',
  edit: 'edit',
  delete: 'delete',
}
export const draftOptions: DropDownItem[] = [
  {
    label: 'Create this exclusion',
    itemValue: draftOperations.create,
  },
  {
    label: 'Delete draft',
    itemValue: draftOperations.delete,
  },
  {
    label: 'Modify & review draft',
    itemValue: draftOperations.edit,
  },
]

//TODO: replace with enum in lib
export const exclusionStatus = {
  Archived: 'Archived',
  Draft: 'Draft',
  NotRunning: 'Not Running',
  Running: 'Running',
  Stopped: 'Stopped',
}

export const maxMilliseconds = <const>8640000000000000

export const lcaSourceFilter = <const>{
  id: '53b35ed3-d623-48e8-a93d-84ee54dfc998',
  name: 'Local Coverage Article/Determinations (LCA/LCD)',
}

export const policyFilterAnalyticsTagMap: Record<string, string> = {
  insight_mode: 'filters-policy-mode',
  topic: 'filters-policy-topic',
  edit_type: 'filters-logic-type',
  source_type_level_1: 'filters-source',
  region: 'filters-geography',
  policy_updates: 'filters-search-policies',
}

export const changelogFilterAnalyticsTagMap: Record<string, string> = {
  policy: 'filters-policy',
  exclusion: 'filters-exclusion',
  user: 'filters-user',
  action: 'filters-action',
}

export const exclusionFilterAnalyticsTagMap: Record<string, string> = {
  status: 'filter-status',
  policy: 'filter-policy',
  exclusion_type: 'filter-type',
  date_type: 'filter-date-type',
  category: 'filter-category',
  provider_tin: 'filter-provider-tin',
  provider_npi: 'filter-provider-npi',
  group_id: 'filter-group-id',
  edit_type: 'filter-logic-type',
  procedure_code: 'filter-proc-code',
  place_of_service: 'filter-place-of-service',
  modifier: 'filter-modifier',
  diagnosis_code: 'filter-diagnosis-code',
  specialty: 'filter-specialty-code',
  contract_id: 'filter-contract-id',
  network_id: 'filter-network-id',
  alt_id: 'filter-alt-id',
  ltss_indicator: 'filter-ltss',
}

export const claimsFilterAnalyticsTagMap: Record<string, string> = {
  policy_ids: 'filter-policy',
  edit_types: 'filter-logic-type',
  place_of_service: 'filter-place-of-service',
  exclusion_ids: 'filter-excluded-insight',
  modifier_codes: 'filter-modifier',
  concept_id: 'filter-concept-id',
  selected_edit: 'filter-selected-insight',
  provider_alt_ids: 'filter-provider-alt-id',
  contract_ids: 'filter-contract-id',
  network_ids: 'filter-provider-network-id',
  provider_npis: 'filter-provider-npi',
  provider_tins: 'filter-provider-tin',
  member_group_ids: 'filter-group-id',
  patient_sex: 'filter-patient-sex',
  member_ids: 'filter-member-id',
  date_of_service_range: 'filter-date-of-service',
  diagnosis_codes: 'filter-diagnosis-code',
  procedure_codes: 'filter-procedure-code',
  insight_mode: 'filter-insight-mode',
}

export const claimsFilterCheckboxAnalyticsTagMap: Record<string, string> = {
  policy_ids: 'checkbox-filter-policy',
  edit_types: 'checkbox-filter-logic-type',
  place_of_service: 'checkbox-filter-place-of-service',
  exclusion_ids: 'checkbox-filter-excluded-insight',
  modifier_codes: 'checkbox-filter-modifier',
  concept_id: 'checkbox-filter-concept-id',
  selected_edit: 'checkbox-filter-selected-insight',
  provider_alt_ids: 'checkbox-filter-provider-alt-id',
  contract_ids: 'checkbox-filter-contract-id',
  network_ids: 'checkbox-filter-provider-network-id',
  provider_npis: 'checkbox-filter-provider-npi',
  provider_tins: 'checkbox-filter-provider-tin',
  member_group_ids: 'checkbox-filter-group-id',
  patient_sex: 'checkbox-filter-patient-sex',
  member_ids: 'checkbox-filter-member-id',
  date_of_service_range: 'checkbox-filter-date-of-service',
  diagnosis_codes: 'checkbox-filter-diagnosis-code',
  procedure_codes: 'checkbox-filter-procedure-code',
  insight_mode: 'checkbox-filter-insight-mode',
}

export const scheduleTimeDefault = '00:01'

export const sinceInceptionDate = <const>'2001-01-01'

export const sortColumnMapExclusions = <const>{
  category: 'category.name',
  claim_line_type: 'claim_line_type',
  effective_by: 'effective_by',
  effective_date_start: 'effective_date_start',
  effective_date_end: 'effective_date_end',
  impacted_claim_count: 'impact.claim_count',
  min_savings_opportunity: 'impact.min_savings',
  max_savings_opportunity: 'impact.max_savings',
  name: 'name',
  status: 'status',
}

export const sortColumnMapPolicies = <const>{
  impacted_providers: 'insight.providers_impacted',
  insight_count_active: 'insight.insight_count_active',
  insight_count_observation: 'insight.insight_count_observation',
  name: 'policy.name',
  savings_active: 'insight.savings_active',
  savings_observation: 'insight.savings_observation',
  status: 'insight_mode',
  // TODO: Remove when Feature Flag removed
  insight_count: 'insight.count',
  savings: 'insight.savings_opportunity',
  // END Feature Flag to be removed
}
