<script setup lang="ts">
import type { Placement } from '@floating-ui/dom'
import { isAfter } from 'date-fns'
import { datePresets, DateRangePresetKey } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

const workspace = useWorkspace()

const { presets, activePreset, sinceInceptionPreset } = useDatePresets()

defineProps<{
  placement: Placement
}>()

const { todayDate } = useClock()

const startDate = ref(
  workspace.selectedDateRange.start ?? getYearToDateRange().start,
)
const endDate = ref(workspace.selectedDateRange.end ?? todayDate.value)
const customActive = ref(false)

const activePresetId = computed(() =>
  customActive.value ? DateRangePresetKey.Custom : activePreset.value.id,
)

const cursorClass = computed(() =>
  activePresetId.value === DateRangePresetKey.Custom ? 'cursor-pointer' : '',
)

debouncedWatch(
  [startDate, endDate],
  ([newStartDate, newEndDate], [oldStartDate, oldEndDate]) => {
    const end = new Date(newEndDate)
    if (isAfter(new Date(newStartDate), end)) {
      startDate.value = newEndDate
      endDate.value = newStartDate
      return
    }

    if (newStartDate === oldStartDate && newEndDate === oldEndDate) return

    workspace.setSelectedDateRange({
      start: newStartDate,
      end: newEndDate,
      preset: customActive.value ? datePresets.custom : activePreset.value.label,
    })
  },
  {
    debounce: 600,
  },
)

const setCustomMode = () => {
  const startDate = workspace.selectedDateRange.start
  const endDate = workspace.selectedDateRange.end
  workspace.setSelectedDateRange({
    start: startDate,
    end: endDate,
    preset: datePresets.custom,
  })
  
  customActive.value = true
}

const setDateInputs = () => {
  startDate.value = workspace.selectedDateRange.start
  endDate.value = workspace.selectedDateRange.end
}

onMounted(() => {
  setDateInputs()
})

watch(
  () => workspace.connectorId,
  () => {
    customActive.value = false
    if (activePreset.value.id === DateRangePresetKey.SinceInception) {
      setDateInputs()
      return
    }

    if (activePreset.value.id === DateRangePresetKey.Custom) {
      setCustomMode()
    }
  },
  {
    immediate: true,
  },
)

const onSelectPreset = (
  item: PresetItem | PresetItemCustom,
  toggle: (setMenu: boolean) => void,
): void => {
  if (item.id === DateRangePresetKey.Custom || !item.start) {
    setCustomMode()
    toggle(false)
    return
  }

  customActive.value = false

  startDate.value = item.start
  endDate.value = item.end

  workspace.setSelectedDateRange({ start: item.start, end: item.end, preset:item.label })

  toggle(false)
}
</script>

<template>
  <section class="flex h-9" data-analytics="top-bar-date-range">
    <UiMenu :placement="placement">
      <template #activator="{ isOpen, toggle }">
        <button
          class="w-37 focus:outline-secondary-300 relative z-10 flex flex items-center justify-between rounded-l-lg border border-neutral-200 px-3 py-2 text-neutral-800 outline-none focus:outline-2"
          data-analytics="top-bar-date-range-dropdown"
          @click="toggle(!isOpen)"
        >
          <span class="subtitle-2 truncate">
            {{ activePresetId === DateRangePresetKey.Custom ? 'Custom' : activePreset.label }}
          </span>

          <i
            class="i-ph-caret-down-bold block h-4 w-4 transition-transform"
            :class="{ 'rotate-180': isOpen }"
          />
        </button>
      </template>

      <template #default="{ toggle }">
        <ul class="min-w-48 space-y-1 p-1">
          <li v-for="item in presets">
            <UiListItemTick
              :checked="activePresetId === item.id"
              @click="onSelectPreset(item, toggle)"
            >
              {{ item.label }}
            </UiListItemTick>
          </li>
        </ul>
      </template>
    </UiMenu>
    <div
      class="bg-surface-bg leading-0 relative -left-[1px] mx-0 flex items-center rounded-r-lg border border-neutral-200 pl-1 text-neutral-800"
    >
      <input
        v-model="startDate"
        class="date-picker subtitle-2 focus:outline-secondary-300 w-28 p-2"
        :class="cursorClass"
        :max="endDate"
        :min="sinceInceptionPreset.start"
        aria-label="Start Date"
        type="date"
        data-analytics="top-bar-start-date-input"
        data-testid="timeFrame_start-date"
        @focus="setCustomMode"
      />
      <span class="relative mx-1">&ndash;</span>
      <input
        v-model="endDate"
        class="date-picker subtitle-2 focus:outline-secondary-300 w-28 p-2 mr-1"
        :class="cursorClass"
        :max="todayDate"
        :min="startDate"
        aria-label="End Date"
        type="date"
        data-analytics="top-bar-end-date-input"
        data-testid="timeFrame_end-date"
        @focus="setCustomMode"
      />
    </div>
  </section>
</template>

<style scoped>
.date-picker {
  background: transparent url(../assets/date-picker.svg) 90% 50% no-repeat;
}
.date-picker::-webkit-calendar-picker-indicator {
  opacity: 0;
  padding: 4px;
  transform: translateX(2px);
}

.date-picker.cursor-pointer::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
