<script setup lang="ts">
const router = useRouter()
const route = useRoute()
const state = useClaimLabState()
const config = useRuntimeConfig().public

const isProviderInquiries = computed(() => {
  if (import.meta.dev && route.fullPath.includes(':3002')) {
    return true
  }
  return config.datadogRum?.config?.service === 'provider-inquiries'
})

const onExit = () => {
  const { connectorId, claimId } = route.params
  if (isProviderInquiries.value) {
    router.push(`/${connectorId}/${claimId}`)
  } else {
    router.push(`/claims/${claimId}?lob=${connectorId}`)
  }
}
</script>

<template>
  <Transition
    name="bottom-drawer"
    enter-active-class="transition transform-gpu"
    enter-from-class="translate-y-[100vh]"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition transform-gpu"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-[100vh]"
    appear
  >
    <div class="claim-lab-layout">
      <header
        class="bg-surface flex items-center justify-between gap-4 border-b border-neutral-200 px-4 py-2"
      >
        <div class="flex items-center">
          <LogoRialtic class="mr-2 h-6 w-6" icon />
          <span class="subtitle-2 text-neutral-800">
            {{ state.connectorName }}
          </span>
        </div>

        <UiBtnClose class="-my-2 -mr-2 print:hidden" @click="onExit()" />
      </header>
      <div class="relative overflow-hidden">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.claim-lab-layout {
  @apply bg-surface grid h-screen grid-cols-1;
  grid-template-rows: 40px calc(100vh - 40px);
}

@media print {
  .claim-lab-layout {
    display: block;
    grid-template-rows: repeat(auto);
    overflow: visible;
  }
}
</style>
