import { acceptHMRUpdate, defineStore } from 'pinia'
import type { RawInsights } from '~/types'

function insertDotAfterThirdChar(input: string): string {
  if (input.length <= 3) {
    return input
  }
  return `${input.slice(0, 3)}.${input.slice(3)}`
}
interface ClaimLabState {
  autocompleteLoading: Set<string>
  autocompleteStore: Map<string, Set<string>>
  workspaceId: string | undefined
  testResult: RawInsights | undefined
  claimLinesCount: number
  changedFields: Set<string>
  newClaimLineSequence: number | undefined
}

const AutocompleteModel = readonly([
  'diagnosis',
  'modifier',
  'PlaceOfService',
  'procedure',
])

export const useClaimLab = defineStore('claim-lab', {
  state: (): ClaimLabState => {
    return {
      autocompleteLoading: new Set(),
      autocompleteStore: new Map(),
      workspaceId: undefined,
      testResult: undefined,
      claimLinesCount: 0,
      changedFields: new Set(),
      newClaimLineSequence: undefined,
    }
  },

  getters: {
    testResultsBySequence(state): Map<number, RawInsights> | null {
      return state.testResult
        ? state.testResult?.reduce((results, current) => {
            const sequence = current.insight.insight.claimLineSequenceNum

            if (!results.has(sequence)) results.set(sequence, [current])
            else results.get(sequence)?.push(current)

            return results
          }, new Map<number, RawInsights>())
        : null
    },
    totalClaimLines(state): number {
      return state.newClaimLineSequence || state.claimLinesCount
    },

    getAutocompleteItems(state) {
      return (model: string) => {
        return Array.from(state.autocompleteStore.get(model) || [])
          .sort((a, b) => a.localeCompare(b))
          .map((item) => {
            return {
              value: item,
            }
          })
      }
    },
  },

  actions: {
    processRefDataFromRip({ lines }: any = {}) {
      if (!lines) return
      for (const line of lines) {
        if (line.procedure_code) {
          this.updateAutocompleteStore('procedure', [line.procedure_code])
        }
        if (line.place_of_service) {
          this.updateAutocompleteStore('PlaceOfService', [
            line.place_of_service,
          ])
        }

        const modifiers = [
          line.modifier_1,
          line.modifier_2,
          line.modifier_3,
          line.modifier_4,
        ]
        this.updateAutocompleteStore('modifier', modifiers)

        const diagnosis = [
          line.diagnosis_code_1,
          line.diagnosis_code_2,
          line.diagnosis_code_3,
          line.diagnosis_code_4,
        ]
        this.updateAutocompleteStore('diagnosis', diagnosis)
      }
    },

    resetClaimLab() {
      this.$patch({
        testResult: undefined,
        claimLinesCount: 0,
        newClaimLineSequence: undefined,
      })
    },
    setTestResult(data: RawInsights | undefined) {
      this.testResult = data
    },
    setClaimsLinesCount(lines: number) {
      this.claimLinesCount = lines
    },
    setChangedField(fieldName: string) {
      const keyMatch = fieldName.match(/^(.*?)-\d+$/)

      if (fieldName.startsWith('provider_specialty') && keyMatch) {
        for (let i = 1; i <= this.totalClaimLines; i++) {
          this.changedFields.add(`${keyMatch[1]}-${i}`)
        }
      } else {
        this.changedFields.add(fieldName)
      }
    },
    removeChangedField(fieldName: string) {
      const keyMatch = fieldName.match(/^(.*?)-\d+$/)

      if (fieldName.startsWith('provider_specialty') && keyMatch) {
        for (let i = 1; i <= this.totalClaimLines; i++) {
          this.changedFields.delete(`${keyMatch[1]}-${i}`)
        }
      } else {
        this.changedFields.delete(fieldName)
      }
    },
    resetChangedFields() {
      this.changedFields.clear()
    },
    setNewClaimLineSequence(sequence: number | undefined) {
      this.newClaimLineSequence = sequence
    },
    setWorkspaceId(workspaceId: string) {
      this.workspaceId = workspaceId
    },

    async searchAutocompleteStore(model: string, term: string) {
      if (!AutocompleteModel.includes(model)) {
        return
      }
      this.autocompleteLoading.add(model)
      try {
        const results = await $apiFetch(`/api/claim-lab/ref-data/${model}`, {
          params: {
            search_phrase: term,
            requireSearch: true,
          },
        })

        if (!results.length) {
          this.autocompleteLoading.delete(model)
          return
        }

        switch (model) {
          case 'diagnosis':
            return this.updateAutocompleteStore(
              model,
              results.map((r: { code: string }) =>
                insertDotAfterThirdChar(r.code),
              ),
            )
          case 'modifier':
          case 'PlaceOfService':
          case 'procedure':
            return this.updateAutocompleteStore(
              model,
              results.map((r: { code: string }) => r.code),
            )
          default:
            break
        }
      } catch (error) {
      } finally {
        this.autocompleteLoading.delete(model)
      }
    },

    updateAutocompleteStore(model: string, items: string[]) {
      if (!Array.isArray(items) || !items.length) {
        return
      }

      const currentItems = this.autocompleteStore.get(model)

      if (!currentItems) {
        this.autocompleteStore.set(model, new Set(items.filter(Boolean)))
        return
      }

      for (const item of items) {
        if (item && !currentItems.has(item)) {
          currentItems.add(item)
        }
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useClaimLab, import.meta.hot))
