import revive_payload_client_lUPXSSIU9h from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ATF27Ko9WD from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8CFvXHLmjF from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E0SrsEEsWN from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qRHZZLepNw from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dnN6bZG8fs from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rsHpHNwPo0 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hpdAyrvUDP from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_C1GxY6gQjo from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/pwa-icons-plugin.ts";
import pwa_client_epk6YWH0ay from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@4.24.4_vite@6.0_r7z22jrakrlrxpjyd4avv32suy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/qa/argocd-releases/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_8fMk05vpWN from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_lUPXSSIU9h,
  unhead_ATF27Ko9WD,
  router_8CFvXHLmjF,
  payload_client_E0SrsEEsWN,
  navigation_repaint_client_qRHZZLepNw,
  check_outdated_build_client_dnN6bZG8fs,
  chunk_reload_client_rsHpHNwPo0,
  plugin_vue3_hpdAyrvUDP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C1GxY6gQjo,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_epk6YWH0ay,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  formkitPlugin_pZqjah0RUG,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_8fMk05vpWN,
  plugin_PXQeuxRKoc
]