<template>
  <Transition
    name="expand"
    enter-active-class="transition-all transform-gpu"
    enter-from-class="-translate-y-10 opacity-0 max-h-0"
    enter-to-class="translate-y-0 opacity-100 max-h-full"
    leave-active-class="transition-all transform-gpu"
    leave-from-class="translate-y-0 opacity-100 max-h-full"
    leave-to-class="-translate-y-10 opacity-0 max-h-0"
  >
    <slot />
  </Transition>
</template>
